function SuggestController() {

    this.init = function () {

        jQuery('form[data-suggest]').each(function () {
            var $form = $(this),
                $searchBox = $form.find('.tx-solr-suggest'),
                $formAutoComplete;
            //console.log($form);
            //console.log($searchBox);

            $formAutoComplete = $('body');
            /*
            if ($form.find('#search-field').length > 0){
                $formAutoComplete = $form.find('#search-field');
            } else {
                $formAutoComplete = $('body');
            }
            */

            $form.find('.tx-solr-suggest-focus').focus();

            // when no specific container found, use the form as container
            if ($searchBox.length === 0) {
                $searchBox = $form;
            }
            $searchBox.css('position', 'relative');

            // Prevent submit of empty search form
            $form.on('submit', function (e) {
                if ($form.find('.tx-solr-suggest').val() === '') {
                    e.preventDefault();
                    $form.find('.tx-solr-suggest').focus();
                }
            });

            //console.log($form.data('suggest'));
            //console.log($formAutoComplete);

            $form.find('.tx-solr-suggest').devbridgeAutocomplete({
                serviceUrl: $form.data('suggest'),
                dataType: 'jsonp',
                ajaxSettings: {
                    jsonp: "tx_solr[callback]"
                },
                paramName: 'tx_solr[queryString]',
                groupBy: 'category',
                maxHeight: 1000,
                appendTo: $formAutoComplete,
                autoSelectFirst: false,
                triggerSelectOnValidInput: false,
                //width: $searchBox.outerWidth() * 0.66,
                width: $searchBox.outerWidth(),
                onSelect: function (suggestion) {
                    // go to link when selecting found result
                    if (suggestion.data.link) {
                        // Open youtube in overlay
                        if (suggestion.data.link.indexOf('https://www.youtube.com') === 0) {
                            openVideoOverlay(suggestion.data.link);
                        } else {
                            location.href = suggestion.data.link;
                        }
                        // else trigger form submit (do search)
                    } else {
                        $form.trigger('submit');
                    }
                },
                transformResult: function (response) {
                    if (!response.suggestions) return {suggestions: []};
                    var firstSuggestion, result = {
                        suggestions: $.map(response.suggestions, function (count, suggestion) {
                            if (!firstSuggestion) firstSuggestion = suggestion;
                            return {value: suggestion, data: {category: 'suggestion', count: count}};
                        })
                    };

                    $.each(response.documents, function (key, value) {
                        var dataObject = value;

                        var defaultGroup = $form.data('suggest-header') ? $form.data('suggest-header') : 'Top results';
                        dataObject.category = defaultGroup;

                        // if a group is set we try to get a label
                        if(dataObject.group) {
                            dataObject.category = $form.data('suggest-header-' + dataObject.group) ? $form.data('suggest-header-' + dataObject.group) : dataObject.group;
                        }

                        result.suggestions.push(
                            {
                                value: firstSuggestion,
                                data: dataObject
                            }
                        );
                    });

                    return result;
                },
                beforeRender: function (container) {
                    // remove first group header
                    container.find('.autocomplete-group:first').remove();
                    container.addClass('tx-solr-autosuggest');

                    container.addClass('bg-white');
                    container.addClass('border-t');
                    container.addClass('border-b');
                    container.addClass('border-l');
                    container.addClass('border-r');
                    container.addClass('border-bleAkzentfarbe-darkgreen');
                    container.addClass('-mt-px');
                    container.addClass('flex');
                    container.addClass('flex-col');
                    container.addClass('items-start');
                    //container.addClass('gap-1.5');
                    container.addClass('p-2.5');

                    // Top search
                    var topSearch = container.find('.autocomplete-group');
                    topSearch.addClass('py-bundPadding-5px w-full');
                    topSearch.html('<p class="before:border-b before:border-bundFarben-hellgrau1 before:inset-x-0 before:absolute before:top-1/2 before:content-[\'\'] text-center relative"><span class="!inline-block bg-white relative px-bundPadding-5px"><i class="far fa-thumbs-up" aria-hidden="true"></i> <span class="uppercase">' + topSearch.text() + '</span></span></p>');

                    // add active class to container
                    $searchBox.parent().addClass('autocomplete-active').fadeIn();
                },
                formatResult: function (suggestion, currentValue) {
                    // Do not replace anything if there current value is empty
                    if (!currentValue) {
                        return suggestion.value;
                    }
                    var pattern = '(' + $.Autocomplete.utils.escapeRegExChars(currentValue.trim()) + ')';
                    // normal suggestion
                    if (suggestion.data.category === 'suggestion') {
                        var suggestionValue = suggestion.value
                            .replace(new RegExp(pattern, 'gi'), '<span>$1<\/span>')
                            .replace(/&/g, '&amp;')
                            .replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;')
                            .replace(/"/g, '&quot;')
                            .replace(/&lt;(\/?span)&gt;/g, '<$1>');

                        return suggestionValue;
                    // Top Treffer
                    } else {
                        //console.log(suggestion.data);
                        var title = suggestion.data.title
                            .replace(new RegExp(pattern, 'gi'), '<em>$1<\/em>')
                            .replace(/&/g, '&amp;')
                            .replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;')
                            .replace(/"/g, '&quot;')
                            .replace(/&lt;(\/?em)&gt;/g, '<$1>');

                        var type = suggestion.data.type;

                        var tel = suggestion.data.orgaTelefon_stringS;
                        if (tel) {
                            tel = ', <span class="bund-text-color-hellgrau1">' + tel + '</span>';
                        }

                        var organisationseinheit = suggestion.data.orgaNummer_stringS;

                        // Add icon to type Pages
                        if (type === 'pages') {
                            title = '<i class="fas fa-file-alt fa-fw"></i> ' + title;
                        }

                        // Add icon to type News
                        if (type === 'tx_news_domain_model_news') {
                            title = '<i class="fas fa-newspaper fa-fw"></i> ' + title;
                        }

                        // Add icon to type sys_file_metadata
                        if (type === 'sys_file_metadata') {
                            // Get filename from link
                            var filename = suggestion.data.link.split("/").pop();
                            title = '<i class="fas fa-download fa-fw"></i> ' + filename;
                        }

                        // Add icon to type Glossar
                        if (type === 'tx_glossaryone_domain_model_term') {
                            title = '<i class="fas fa-book fa-fw"></i> ' + title;
                        }

                        // Add icon to type Telefonbuch
                        if (type === 'tx_bletelefonbuch_domain_model_telefonbuch') {
                            //console.log(suggestion.data);
                            title = '<i class="fas fa-phone fa-fw"></i> ' + title;
                        }

                        return '<div class="' + suggestion.data.type + '">' +
                            (!!suggestion.data.previewImage ? '<figure ' + (!!suggestion.data.hasVideo ? 'class="hasVideo"' : '') + '><img src="' + suggestion.data.previewImage + '" /></figure>' : '') +
                            '<a href="' + suggestion.data.link + '" class="internal-link">' + title + '</a>' +
                            tel +
                            '</div>';
                    }

                }
            }).on('blur', function () {
                $searchBox.parent().removeClass('autocomplete-active');
                var $box = $(this);
                setTimeout(function () {
                    $box.devbridgeAutocomplete('hide');
                }, 200);

            });

        });
    };
}

jQuery(document).ready(function() {
    /** solr search autocomplete **/
    var solrSuggestController = new SuggestController();
    solrSuggestController.init();

    jQuery("body").on("tx_solr_updated", function() {
        solrSuggestController.init();
    });

    var SolrTopSearch = $('#topsearch-field');
    var SolrMainSearch = $('#search-field');

    SolrTopSearch.focus(function(){
        $('div.autocomplete-suggestions').outerWidth($('input#topsearch-field').outerWidth()+'px');
    });
    SolrMainSearch.focus(function(){
        $('div.autocomplete-suggestions').outerWidth($('input#search-field').outerWidth()+'px');
    });

});

